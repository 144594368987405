import { z } from "zod";

export const TaskSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  details: z.string().optional(),
  createdAt: z.date(),
  updatedAt: z.date().nullable(),
  removedAt: z.date().nullable(),
  creatorId: z.string().uuid(),
  organizationId: z.string().uuid(),
  private: z.boolean(),
  completed: z.boolean(),
  updatorId: z.string().nullable(),
  creator: z.object({
    name: z.string(),
  }),
});

export type Task = z.infer<typeof TaskSchema>;
