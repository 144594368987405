import dayjs from "dayjs";

export enum Frequency {
  Month = "month",
  Year = "year",
}

export enum Period {
  Today = "today",
  Last7Days = "last-7-days",
  MonthToDate = "month-to-date",
  LastMonth = "last-month",
  Last30Days = "last-30-days",
  Last3Months = "last-3-months",
}

/**
 * Helper to get the starting and ending timestamps for a given period.
 * Also provides the timestamps for the previous period (e.g. period: last week, previous period = 2 weeks ago),
 * useful for filtering and comparing data (e.g. a brand sales growth)
 *
 * @param period
 * @returns
 */
export function getPeriodTimeFrame(period: Period): {
  start: number;
  end: number;
  previousPeriodStart: number;
  previousPeriodEnd: number;
  unit: "day" | "month";
  previousPeriodSubstract: number;
} {
  switch (period) {
    case Period.Today: {
      const startOfToday = dayjs().startOf("day");
      const endOfToday = dayjs().endOf("day");
      return {
        start: startOfToday.unix(),
        end: endOfToday.unix(),
        previousPeriodStart: startOfToday
          .subtract(1, "day")
          .startOf("day")
          .unix(),
        previousPeriodEnd: endOfToday.subtract(1, "day").endOf("day").unix(),
        unit: "day",
        previousPeriodSubstract: 1,
      };
    }
    case Period.Last7Days: {
      const startOfLast7Days = dayjs().subtract(7, "day").startOf("day");
      const endOfLast7Days = dayjs().endOf("day");
      return {
        start: startOfLast7Days.unix(),
        end: endOfLast7Days.unix(),
        previousPeriodStart: startOfLast7Days
          .subtract(7, "day")
          .startOf("day")
          .unix(),
        previousPeriodEnd: endOfLast7Days
          .subtract(7, "day")
          .endOf("day")
          .unix(),
        unit: "day",
        previousPeriodSubstract: 7,
      };
    }
    case Period.Last30Days: {
      const startOfLast30Days = dayjs().subtract(30, "day").startOf("day");
      const endOfLast30Days = dayjs().endOf("day");
      return {
        start: startOfLast30Days.unix(),
        end: endOfLast30Days.unix(),
        previousPeriodStart: startOfLast30Days
          .subtract(30, "day")
          .startOf("day")
          .unix(),
        previousPeriodEnd: endOfLast30Days
          .subtract(30, "day")
          .endOf("day")
          .unix(),
        unit: "day",
        previousPeriodSubstract: 30,
      };
    }
    case Period.MonthToDate: {
      const monthToDateStart = dayjs().startOf("month");
      const monthToDateEnd = dayjs();
      return {
        start: monthToDateStart.unix(),
        end: monthToDateEnd.unix(),
        previousPeriodStart: monthToDateStart
          .subtract(1, "month")
          .startOf("month")
          .unix(),
        previousPeriodEnd: monthToDateEnd.subtract(1, "month").unix(),
        unit: "month",
        previousPeriodSubstract: 1,
      };
    }
    case Period.LastMonth: {
      const lastMonthStart = dayjs().subtract(1, "month").startOf("month");
      const lastMonthEnd = dayjs().subtract(1, "month").endOf("month");
      return {
        start: lastMonthStart.unix(),
        end: lastMonthEnd.unix(),
        previousPeriodStart: lastMonthStart
          .subtract(1, "month")
          .startOf("month")
          .unix(),
        previousPeriodEnd: lastMonthEnd
          .subtract(1, "month")
          .endOf("month")
          .unix(),
        unit: "month",
        previousPeriodSubstract: 1,
      };
    }
    case Period.Last3Months: {
      const startOfLast3Months = dayjs().subtract(3, "month").startOf("month");
      const endOfLast3Months = dayjs().endOf("month");
      return {
        start: startOfLast3Months.unix(),
        end: endOfLast3Months.unix(),
        previousPeriodStart: startOfLast3Months
          .subtract(3, "month")
          .startOf("month")
          .unix(),
        previousPeriodEnd: endOfLast3Months
          .subtract(3, "month")
          .endOf("month")
          .unix(),
        unit: "month",
        previousPeriodSubstract: 3,
      };
    }
    default:
      throw new Error(`Period time frame ${period} not supported`);
  }
}
