import {
  Box,
  Button,
  Card,
  Center,
  ScrollAreaAutosize,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import {
  IconError404,
  IconFileAlert,
  IconLockAccessOff,
} from "@tabler/icons-react";
import type { ErrorCode } from "core";
import { useEffect } from "react";
import { Link, useRouteError } from "react-router-dom";
import { isDeployed } from "../config";
import { captureAndReportError } from "../sentry";
import type { TablerIcon } from "../utils/icons";

interface ErrorWithStatus {
  status?: number;
}

const errorCodeToTitle: Record<
  ErrorCode,
  {
    title: string;
    description: string;
    icon: TablerIcon;
  }
> = {
  403: {
    title: "No Access",
    description: "You don't appear to have permission to access this page",
    icon: IconLockAccessOff,
  },
  404: {
    title: "Page not found",
    description: "The page you are looking for does not exist.",
    icon: IconError404,
  },
  500: {
    title: "Unexpected error",
    description: "An unexpected error ocurred. Our team has been notified.",
    icon: IconFileAlert,
  },
} as const;

function isError(err: unknown): err is Error {
  return err instanceof Error;
}

/**
 * Resolves an arbitrary number code into a valid ErrorCode,
 * converting to a masked code if necessary (e.g 403 -> 500 if
 * no 403 handler is implemented)
 */
function asErrorCode(code: number): ErrorCode {
  switch (code) {
    case 404:
      return 404;
    default:
      return 500;
  }
}

export function ErrorPage({
  code,
  title,
  description,
}: {
  code?: ErrorCode;
  title?: string;
  description?: string;
}) {
  const error = useRouteError();
  const resolvedCode = asErrorCode(
    code ?? (error as ErrorWithStatus)?.status ?? 500,
  );
  const Icon = errorCodeToTitle[resolvedCode].icon;

  useEffect(() => {
    if (isError(error)) {
      console.error(error);
      captureAndReportError(error);
    }
  }, [error]);

  return (
    <Center component={Stack} align="center" p="xl">
      <Icon size={180} />
      <Title fw="bold">{title ?? errorCodeToTitle[resolvedCode].title}</Title>
      <Text c="gray.6">
        {description ?? errorCodeToTitle[resolvedCode].description}
      </Text>

      {!isDeployed && isError(error) && (
        <Card miw={500} maw={800}>
          <ScrollAreaAutosize scrollbars="xy">
            <pre>{error.stack}</pre>
          </ScrollAreaAutosize>
        </Card>
      )}

      <Box mt="md">
        <Button component={Link} to="/" variant="light">
          Return to main page
        </Button>
      </Box>
    </Center>
  );
}
