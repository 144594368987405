import { z } from "zod";

export const zodJsonStringAsDate = z.preprocess(
  (d) => (typeof d === "string" ? new Date(d) : d),
  z.date(),
);

export const zodJsonStringAsDateMaybe = z.preprocess(
  (d) => (typeof d === "string" ? new Date(d) : d),
  z.date().optional().nullable(),
);
