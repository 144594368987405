import {
  Container,
  Group,
  Stack,
  Text,
  Title,
  type MantineSize,
} from "@mantine/core";
import clsx from "clsx";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { ScrollRestoration, type Location } from "react-router-dom";
import classes from "./Page.module.css";
import { BackButton } from "./buttons/BackButton";

interface PageDetails {
  title?: string;
  titleLeftSection?: JSX.Element;
  titleRightSection?: JSX.Element;
  description?: string;
}

export function PageMeta({ title, description }: PageDetails) {
  return (
    <Helmet>
      <title>{title ? `NOAN - ${title}` : "NOAN"}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:url" content={document.location.toString()} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}

function getScrollRestorationKey(location: Location) {
  return location.pathname;
}

export function Page({
  children,
  setPageMeta = true,
  title = "",
  containerSize,
  titleLeftSection,
  titleRightSection,
  description,
  documentTitle,
  pageActions,
  backButton,
  aboveTitleSection = null,
  renderDescription,
  renderTitle,
}: React.PropsWithChildren<
  PageDetails & {
    setPageMeta?: boolean;

    /** If set, is used as the document's title instead of the title property */
    documentTitle?: string;

    /** Render arbitrary content above the title */
    aboveTitleSection?: JSX.Element | null;

    size?: MantineSize;
    containerSize?: MantineSize;
    pageActions?: JSX.Element[];

    /**
     * Allows controlling rendering for specific elements, while preserving the
     * string values for opengraph/metadata:
     */
    renderDescription?: (description: string) => JSX.Element;
    renderTitle?: (title: string) => JSX.Element;

    backButton?: {
      label: string;
      to?: string;
    };
  }
>) {
  return (
    <Container
      classNames={{
        root: clsx(
          classes.container,
          !containerSize && classes.containerWithWidth,
        ),
      }}
      size={containerSize}
    >
      {setPageMeta && (
        <PageMeta title={documentTitle ?? title} description={description} />
      )}

      {backButton && <BackButton label={backButton.label} to={backButton.to} />}

      <div className={classes.aboveTitleSection}>{aboveTitleSection}</div>

      {(title || description || pageActions) && (
        <Group gap="sm" align="center">
          {(title || description) && (
            <Stack gap={0} py="lg" flex={1}>
              {title && (
                <Group>
                  {titleLeftSection}
                  <Title order={1} size={50} fw="bold">
                    {renderTitle ? renderTitle(title) : title}
                  </Title>
                  {titleRightSection}
                </Group>
              )}

              {description && (
                <Text maw="65ch">
                  {renderDescription
                    ? renderDescription(description)
                    : description}
                </Text>
              )}
            </Stack>
          )}

          {pageActions && pageActions.length > 0 && (
            <Group gap="xs">
              {pageActions.map((p, i) => (
                <Fragment key={i}>{p}</Fragment>
              ))}
            </Group>
          )}
        </Group>
      )}

      {children}
      <ScrollRestoration getKey={getScrollRestorationKey} />
    </Container>
  );
}
