import { z } from "zod";

export enum Connector {
  Stripe = "stripe",
  GoogleAnalytics = "google-analytics",
}

export enum GABlockPathKeys {
  GA = "ga",
}

export enum NangoModelName {
  GAReportPerformanceOverview = "GAReportPerformanceOverview",
  GAReportTrafficOverview = "GAReportTrafficOverview",
  GAReportTopContent = "GAReportTopContent",
  GAReportTrafficSource = "GAReportTrafficSource",
  GAReportEngagement = "GAReportEngagement",
}

export const NangoIntegrationIds = {
  [Connector.Stripe]: "com.noan.stripe-connector",
  [Connector.GoogleAnalytics]: "com.noan.ga-connector",
} as const;

export const nangoIntegrations = Object.values(NangoIntegrationIds);

const NangoConnectionSchema = z.object({
  nangoConnectionId: z.string(),
  nangoIntegrationId: z.string(),
});

const StripeConnectorConfigurationSchema = z
  .object({
    connector: z.literal(Connector.Stripe),

    /**
     * All the fields that follow are deprecated.
     * We will retrieve that data from Nango directly from now on whenever needed.
     * @deprecated
     */
    stripeAccountId: z.string().optional(),
    /**
     * @deprecated
     */
    scope: z.string().optional(),
    /**
     * @deprecated
     */
    accessToken: z.string().optional(),
    /**
     * @deprecated
     */
    refreshToken: z.string().optional(),
    /**
     * @deprecated
     */
    tokenType: z.string().optional(),
  })
  .merge(NangoConnectionSchema);

const GoogleAnalyticsConnectorConfigurationSchema = z
  .object({
    connector: z.literal(Connector.GoogleAnalytics),
    connectedAccount: z
      .object({
        id: z.string(),
        name: z.string(),
        propertyName: z.string(),
        propertyDisplayName: z.string(),
      })
      .optional(),
  })
  .merge(NangoConnectionSchema);

export const ConnectorConfigurationSchema = z.discriminatedUnion("connector", [
  StripeConnectorConfigurationSchema,
  GoogleAnalyticsConnectorConfigurationSchema,
]);

export type GoogleAnalyticsConnectorConfiguration = z.infer<
  typeof GoogleAnalyticsConnectorConfigurationSchema
>;

export type ConnectorConfiguration = z.infer<
  typeof ConnectorConfigurationSchema
>;

export function isConnectorGoogleAnalytics(
  configuration?: ConnectorConfiguration,
) {
  return configuration?.connector === Connector.GoogleAnalytics;
}
