import { User } from "@supabase/supabase-js";
import type { IdentityWithAccountId } from "core";
import { createContext } from "react";

interface AuthOptionalContextProps {
  signOut: () => void;
  isLoadingUser: boolean;
  authError: Error | null;
  jwtBearerToken: string | null;
  hasThirdPartyProvider: boolean;
  mustSetPassword: boolean;
  mustCompletePostSignup: boolean;
}

interface AuthContextProps {
  user: User;
  identity: IdentityWithAccountId;
  isLoadingIdentity: boolean;
  isAccountActive: boolean;
  isCustomer: boolean;
  isPayingCustomer: boolean;
  hasInsightsAccess: boolean;
  mustCompleteOnboarding: boolean;
}

export const AuthContext = createContext<
  (AuthOptionalContextProps & AuthContextProps) | null
>(null);
export const AuthOptionalContext = createContext<
  (AuthOptionalContextProps & Partial<AuthContextProps>) | null
>(null);
