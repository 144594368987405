/**
 * List of explicit zIndex values used in the app.
 *
 * Mantine positions overlays around the 300 mark.
 */
export enum ZedIndex {
  Sidebar = 106,
  ChatButton = 105,
  ReturnToTop = 103,
  ReportBar = 102,
  StickyToolbar = 101,
  Header = 100,
  Overlay = 99,
  LearnDrawer = 301, // Mantine tooltip has zIndex=300
}
