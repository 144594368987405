import { z } from "zod";

/**
 * Defines the schema + validation for an insights report (v1), including
 * presentation and structural data.
 */
export const InsightReportDefinitionSchema = z
  .object({
    version: z.literal(1),
    title: z.string(),
    description: z.string(),
    includeOrganizationStrategy: z.boolean().default(false),
    tone: z
      .object({
        text: z.string(),
      })
      .optional(),
    insights: z.array(
      z.object({
        id: z.string().uuid(),
        includeFullContext: z.boolean().default(false),
      }),
    ),
  })
  .strict();

export type InsightReportDefinition = z.infer<
  typeof InsightReportDefinitionSchema
>;
