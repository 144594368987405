import { z } from "zod";

// Used for granted promo code to use for subscribing to a NOAN plan. These are granted by NOAN admins.
const PromoCodeSchema = z.object({
  organizationId: z.string().uuid(),
  code: z.string(),
});

export const UserMetadataSchema = z.object({
  identityId: z.string().uuid().optional(),
  organizationId: z.string().uuid().nullish(),
  promoCodes: z.array(PromoCodeSchema).optional(),
});

export type PromoCode = z.infer<typeof PromoCodeSchema>;
export type UserMetadata = z.infer<typeof UserMetadataSchema>;
