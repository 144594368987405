import { z } from "zod";
import { assistantIds } from "../generated/storyblok/assistants";

export const AssistantSchema = z.object({
  id: z.enum(assistantIds),
  name: z.string(),
  description: z.string(),
  prompt: z.string(),
  iconName: z.string().optional(),
  priority: z.number().optional(),
});

export const PublicAssistantSchema = AssistantSchema.pick({
  id: true,
  name: true,
  description: true,
});

export type Assistant = z.infer<typeof AssistantSchema>;
