import {
  Anchor,
  BackgroundImage,
  Badge,
  Blockquote,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  Stack,
  Text,
  ThemeIcon,
  Title,
  TypographyStylesProvider,
  rgba,
} from "@mantine/core";
import {
  ISbStoryData,
  StoryblokComponent,
  apiPlugin,
  renderRichText,
  storyblokInit,
} from "@storyblok/react/rsc";

import {
  IconArticle,
  IconClockHour2,
  IconCopyright,
  IconQuote,
  IconStack2,
} from "@tabler/icons-react";
import { compact } from "core";
import { Link } from "react-router-dom";
import { InstagramEmbed, XEmbed, YouTubeEmbed } from "react-social-media-embed";
import {
  ContentCarouselStoryblok,
  FeatureStoryblok,
  HeaderImageTextOverlaidStoryblok,
  ImageStoryblok,
  PageStoryblok,
  PullQuoteStoryblok,
  SocialMediaEmbedStoryblok,
  SubheadStoryblok,
  TextStoryblok,
  TitleStoryblok,
  type ArticleRichTextStoryblok,
} from "../../storyblok-types";
import { slugToIntelPath } from "../../utils/storyblokHelpers";

function estimateReadingTime(story: PageStoryblok["blok"]) {
  const textBlockLengths = (
    (story.content.body as PageStoryblok["body"]) ?? []
  ).reduce((acc, blok) => {
    switch (blok.component) {
      case "pull-quote":
        return acc + (blok.pullquote ?? "").length;
      case "text": {
        const richTextBloks = blok.text?.content ?? [];
        const subSum = richTextBloks
          .flatMap((t) => t.content ?? [])
          .reduce((subAcc, t) => {
            return subAcc + (t.text?.length ?? 0);
          }, 0);

        return acc + subSum;
      }
      default:
        return acc;
    }
  }, 0);

  // Estimated 6 char average per word
  const estimatedWords = textBlockLengths / 6;
  // Estimated 200 wpm reading average
  const readingTimeMinutes = estimatedWords / 200;

  return Math.min(Math.round(readingTimeMinutes), 9);
}

export function ReadingTimeBadge({ timeMinutes }: { timeMinutes: number }) {
  return (
    <Badge
      variant="filled"
      color="base.5"
      size="sm"
      leftSection={<IconClockHour2 size={14} />}
    >
      {timeMinutes} minute read
    </Badge>
  );
}

export function UrlEmbed({ url }: { url: string }) {
  const parsedUrl = new URL(url);
  const bareHostname = parsedUrl.hostname.replace("www.", "");

  switch (bareHostname) {
    case "instagram.com":
      return <InstagramEmbed url={url} />;
    case "youtube.com":
      return <YouTubeEmbed url={url} />;
    case "x.com":
    case "twitter.com":
      return <XEmbed url={url} />;
    default:
      <Anchor component={Link} to={url} target="_blank" rel="nofollow">
        {url}
      </Anchor>;
  }
}

/**
 * Shared component for plain and rich text.
 */
export function TextComponent({ blok }: { blok: TextStoryblok }) {
  if (typeof blok.text === "string") {
    return blok.text;
  }

  return (
    <TypographyStylesProvider>
      <Box
        className="storyblok-css"
        dangerouslySetInnerHTML={{
          __html: blok.text != null ? renderRichText(blok.text) : "",
        }}
      ></Box>
    </TypographyStylesProvider>
  );
}

export function CarouselItem({
  link,
}: {
  link: ContentCarouselStoryblok["link1"];
}) {
  const story = link?.story as ISbStoryData;
  const readingTime = estimateReadingTime(story);

  const headerComponent = (
    (story.content.body as PageStoryblok["body"]) ?? []
  ).find((b) => b.component === "header-image-text-overlaid");

  const backgroundImage =
    (headerComponent as HeaderImageTextOverlaidStoryblok | undefined)?.image
      ?.filename || "/intel-bg.svg";

  const url = link?.cached_url ? slugToIntelPath(link.cached_url) : "";

  return (
    <Card shadow="none" p={0}>
      <BackgroundImage
        src={backgroundImage}
        h={{ base: 140, md: 200 }}
        bgp="top center"
      >
        <Anchor component={Link} to={url} c="inherit" underline="never">
          <Stack justify="space-between" h="100%">
            <Group p="sm" justify="flex-end">
              <ReadingTimeBadge timeMinutes={readingTime} />
            </Group>
            <Box
              style={{
                background: rgba("black", 0.7),
                backdropFilter: "blur(4px)",
              }}
              px="sm"
              py="xs"
            >
              <Text lineClamp={2} c="dimmed">
                {story.name}
              </Text>
            </Box>
          </Stack>
        </Anchor>
      </BackgroundImage>
    </Card>
  );
}

// Init Storyblok, and connect our individual component types:
storyblokInit({
  apiOptions: {
    region: "us",
    cache: {
      clear: "auto",
      type: "memory",
    },
    rateLimit: 5,
  },
  accessToken: import.meta.env.VITE_STORYBLOK_TOKEN,
  use: [apiPlugin],
  components: {
    articleRichText: ({ blok }: { blok: ArticleRichTextStoryblok }) => (
      <TextComponent
        blok={{
          _uid: blok._uid,
          component: "text",
          text: blok.content,
        }}
      />
    ),
    Title: ({ blok }: { blok: TitleStoryblok }) => (
      <>
        <Title order={1} mb="md">
          {blok.text}
        </Title>
        <Divider mb="lg" />
      </>
    ),
    text: TextComponent,
    subhead: ({ blok }: { blok: SubheadStoryblok }) => {
      if (blok.subhead == null || blok.subhead === "") return null;

      return (
        <Box>
          <Group gap="sm">
            <Badge size="lg" variant="light" color="teal">
              <IconStack2 size={24} />
            </Badge>
            <Title order={3}>{blok.subhead}</Title>
          </Group>
        </Box>
      );
    },
    page: ({ blok }) => {
      return (
        <main>
          {blok.body.map((nestedBlok: PageStoryblok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </main>
      );
    },

    image: ({ blok }: { blok: ImageStoryblok }) => {
      return (
        <Flex align="center" justify="center">
          <Card m="xl" radius="lg" p={"xs"} withBorder>
            <Stack gap="xs">
              <Image
                fit="scale-down"
                radius="md"
                src={blok.image?.filename}
                alt={blok.image?.alt}
                mah={400}
              />
              {(blok.image?.title || blok?.image?.copyright) && (
                <Group>
                  {blok.image?.title && (
                    <Text size="xs">{blok.image.title}</Text>
                  )}
                  {blok.image?.copyright && (
                    <Badge
                      color="gray.6"
                      variant="light"
                      leftSection={<IconCopyright size={14} />}
                    >
                      {blok.image?.copyright}
                    </Badge>
                  )}
                </Group>
              )}
            </Stack>
          </Card>
        </Flex>
      );
    },

    "pull-quote": ({ blok }: { blok: PullQuoteStoryblok }) => {
      return (
        <Blockquote
          icon={
            <ThemeIcon size="lg">
              <IconQuote />
            </ThemeIcon>
          }
          p="lg"
          my="xl"
          iconSize={24}
        >
          <Text size="lg">{blok.pullquote}</Text>
        </Blockquote>
      );
    },

    feature: ({ blok }: { blok: FeatureStoryblok }) => {
      const actionUrl = blok.action.cached_url;

      const url = actionUrl != null ? slugToIntelPath(actionUrl) : "/";

      return (
        <Grid columns={6} my="xl">
          <Grid.Col span={{ base: 6, md: 3 }}>
            <Card p={0}>
              <Image fit="cover" mah={300} src={blok.image?.filename ?? ""} />
            </Card>
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 3 }}>
            <Stack p="lg" justify="flex-end" h="100%">
              <Divider
                label={
                  <Group gap="xs">
                    <IconArticle size={14} /> Feature
                  </Group>
                }
                mb="md"
                flex={1}
              />
              <Box>
                {blok?.text && (
                  <TextComponent
                    blok={{
                      component: "text",
                      text: blok.text,
                      _uid: blok._uid,
                    }}
                  />
                )}
              </Box>

              <Button
                component={Link}
                to={url}
                color="teal"
                variant="light"
                size="lg"
              >
                {blok.calltoaction}
              </Button>
            </Stack>
          </Grid.Col>
        </Grid>
      );
    },

    // Spacers aren't rendered at all.
    spacer: () => null,

    "content-carousel": ({ blok }: { blok: ContentCarouselStoryblok }) => {
      const links = compact([blok.link1, blok.link2, blok.link3]);

      return (
        <Box py="sm" mb="lg">
          <Grid columns={3}>
            {links.map((link) => (
              <Grid.Col span={{ base: 3, md: 1 }} key={link.cached_url}>
                <CarouselItem link={link} />
              </Grid.Col>
            ))}
          </Grid>
        </Box>
      );
    },

    "social-media-embed": ({ blok }: { blok: SocialMediaEmbedStoryblok }) => {
      const url = blok.url;

      if (url == null) return null;

      return (
        <Box my="lg">
          <Flex h="100%" align="center" justify="center">
            <Card withBorder h="100%" mah={580} pos="relative">
              <UrlEmbed url={url} />
            </Card>
          </Flex>
        </Box>
      );
    },

    // For now at least, we don't render these:
    "header-image-and-text": () => null,
    "header-image-text-overlaid": ({
      blok,
    }: {
      blok: HeaderImageTextOverlaidStoryblok;
    }) => {
      return (
        <Stack>
          <Title order={1}>{blok.headline as string}</Title>
          <BackgroundImage
            h={200}
            src={blok.image?.filename ?? ""}
            radius="lg"
            p="lg"
            mb="lg"
          />
        </Stack>
      );
    },
  },
});
