import { z } from "zod";
import { ConvoBucket } from "./Convo";

export enum NoteProvenanceSource {
  BuildBlock = "build-block",
  GlobalIdea = "global-idea",
}

const NoteProvenanceSchema = z.object({
  source: z.nativeEnum(NoteProvenanceSource),
  blockPath: z.string().optional(),
});

export const NoteContentSchema = z.object({
  provenance: NoteProvenanceSchema,
  title: z.string().optional(),
  bucket: z.nativeEnum(ConvoBucket).optional(),
  plainText: z.string(),
});

export const NoteSchema = z.object({
  id: z.string().uuid(),
  private: z.boolean().default(false),
  creatorId: z.string().uuid(),
  organizationId: z.string().uuid(),
  content: NoteContentSchema,
  createdAt: z.date(),
  updatedAt: z.date().nullable(),
  removedAt: z.date().nullable(),
});

export type Note = z.infer<typeof NoteSchema>;
export type NoteContent = z.infer<typeof NoteContentSchema>;
