import * as Sentry from "@sentry/react";
import type { Identity } from "core";
import posthog from "posthog-js";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { isDeployed, sentryDsn } from "./config.ts";

export function setSentryUser(identity: Pick<Identity, "email" | "id">) {
  Sentry.setUser(identity);
}

export function clearSentryUser() {
  Sentry.setUser(null);
}

export function captureAndReportError(error: Error) {
  Sentry.captureException(error);
}

export function initSentry() {
  if (Sentry.isInitialized()) {
    console.warn("Sentry already initialized");
    return;
  }

  Sentry.init({
    dsn: sentryDsn,

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/beta.getnoan\.com/,
      /^https:\/\/app.getnoan\.com/,
    ],

    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      posthog.sentryIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    enabled: isDeployed,
  });
}

export const createSentryBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
