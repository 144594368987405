import { Button, MantineBreakpoint, ThemeIcon } from "@mantine/core";
import { IconArrowBackUp } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";

export function BackButton({
  label,
  to,
  visibleFrom = "md",
  w,
}: {
  label: string;
  to?: string;
  visibleFrom?: MantineBreakpoint;
  w?: React.CSSProperties["width"];
}) {
  const navigate = useNavigate();

  return (
    <Button
      visibleFrom={visibleFrom}
      variant="light"
      color="dark"
      size="xs"
      component={Link}
      to={to || ""}
      w={w}
      onClick={
        to
          ? undefined
          : () => {
              /** If no `to` is provided, we simply go back in history: */
              navigate(-1);
            }
      }
      leftSection={
        <ThemeIcon variant="transparent" color="dark" size="sm">
          <IconArrowBackUp />
        </ThemeIcon>
      }
    >
      {label}
    </Button>
  );
}
