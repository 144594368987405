import { z } from "zod";
import { AssistantSchema } from "./Assistant";
import { InsightReportDefinitionSchema } from "./InsightReport";

export const aiGenerators = ["openai", "anthropic", "perplexity"] as const;

export enum GeneratorFactory {
  Strategy = "strategy",
  InsightsReport = "insights-report",
  InsightsReportTitle = "insights-report-title",
  Create = "create",
  ChatAssistant = "chat-assistant",
}

export enum MessageRole {
  Assistant = "assistant",
  System = "system",
  User = "user",
}

export const GeneratorUntrustedInputSchema = z
  .object({
    /** The method used for generating content */
    factory: z
      .nativeEnum(GeneratorFactory)
      .default(GeneratorFactory.Strategy)
      .optional(),
    input: z.string().max(500000).optional(),
    role: z.nativeEnum(MessageRole),
    tone: z
      .object({
        text: z.string().max(1000).optional(),
      })
      .optional(),
    thread: z
      .object({
        last: z.string().max(500000).optional(),
        blockPath: z.string().optional(),
        generatorResultId: z.string().uuid().optional(),
        insightsReport: InsightReportDefinitionSchema.optional(),
        insightsReportId: z.string().uuid().optional(),
        convoId: z.string().uuid().optional(),
        assistantPersona: AssistantSchema.shape.id.optional(),
        skipPrompt: z.boolean().default(false).optional(),
      })
      .strict()
      .optional(),
    generator: z.enum(aiGenerators).default("openai").optional(),
    skipSaving: z.boolean().default(false).optional(),
    messages: z
      .array(
        z.object({
          role: z.nativeEnum(MessageRole),
          content: z.string(),
        }),
      )
      .optional(),
  })
  .strict();

export type GeneratorUntrustedInput = z.infer<
  typeof GeneratorUntrustedInputSchema
>;
