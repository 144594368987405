import { z } from "zod";

export const previewConstraints = {
  maxCreatorGeneration: 5,
  maxAssistantGenerationPerUser: 10,
} as const;

export const ItemPriceTierSchema = z.object({
  unit_amount_decimal: z.string().nullable(),
  up_to: z.number().nullable(),
});

export type ItemPriceTier = z.infer<typeof ItemPriceTierSchema>;
