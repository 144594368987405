/**
 * Removes nullish values from an array
 */
export function compact<T>(array: (T | null | undefined)[]): T[] {
  return array.filter((v) => v != null) as NonNullable<T>[];
}

/**
 * Similar to `compact`, but also checks for empty strings
 */
export function compactString<T>(array: (T | null | undefined)[]): T[] {
  return array.filter((v) => v != null && v !== "") as NonNullable<T>[];
}

/**
 * Raises a typescript error + throws if a branch that should be unreachable
 * is, infact, reachable. Can be used for example in switch statements to ensure
 * all options are covered.
 */
export function assertUnreachable(
  _: never,
  message: string = "invariant: Reached unreachable branch or condition",
): never {
  throw new Error(message);
}

/**
 * Tries to split a string containing a full name into parts. Very
 * iffy and sketchy.
 */
export function splitFullName(fullName: string) {
  const name = fullName.split(" ");

  return {
    lastName: name[name.length - 1],
    firstName: name.slice(0, -1).join(" "),
  };
}
