import {
  ActionIcon,
  Anchor,
  AppShell,
  Avatar,
  Badge,
  Box,
  Burger,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Group,
  Image,
  Loader,
  Menu,
  ScrollArea,
  ScrollAreaAutosize,
  Skeleton,
  Spoiler,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
  useComputedColorScheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useLocalStorage, useMediaQuery } from "@mantine/hooks";
import { ModalsProvider } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import {
  IconBulb,
  IconChevronRight,
  IconDesk,
  IconHelp,
  IconHome,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
  IconLogin2,
  IconLogout,
  IconMenu2,
  IconMoon,
  IconPencil,
  IconSchool,
  IconSettings,
  IconStack2,
  IconSun,
  IconSwitchHorizontal,
  IconUser,
  IconUsersGroup,
} from "@tabler/icons-react";
import clsx from "clsx";
import {
  FeatureFlag,
  IdentityRole,
  NoanError,
  OnboardingCalloutKey,
  isNoanError,
} from "core";
import dayjs from "dayjs";
import { usePostHog } from "posthog-js/react";
import { useEffect, useMemo } from "react";
import {
  Link,
  Navigate,
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";
import { useAuthContext, useOptionalAuthContext } from "../auth/useAuthContext";
import { useIdentity, useIsRole } from "../auth/useIdentity";
import { FirstTimeVideoModal } from "../components/FirstTimeVideo";
import { FullPageLoader } from "../components/FullPageLoader";
import { NavLink } from "../components/NavLink";
import { OnboardingCallout } from "../components/OnboardingCallout";
import { ReaderPanelContextProvider } from "../components/ReaderPanel/ReaderPanelContext";
import { NoanLogo } from "../components/icons/NoanLogo.svg";
import { NoanLogoHexagon } from "../components/icons/NoanLogoHexagon.svg";
import { isDeployed } from "../config";
import { useIsFeatureEnabled } from "../utils/useIsFeatureEnabled";
import { useOrgLogo } from "../utils/useOrgLogo";
import { useOrganizationMutation } from "../utils/useOrganizationMutation";
import { useOrganizations } from "../utils/useOrganizations";
import { ZedIndex } from "../utils/zedIndex";
import classes from "./Layout.module.css";
import {
  navigationHeaderHeights,
  topHeaderHeights,
  type LayoutVariant,
} from "./layoutConfig";

interface NavigationLink {
  to: string;
  label: string;
  requiresSession?: boolean;
  hideIfLoggedIn?: boolean;
  hidden?: boolean;
  checkAccess?: () => boolean;
  strictPath?: boolean;
}

function isSignupPage(pathname: string) {
  return pathname === "/join";
}

function isSubscribePage(pathname: string) {
  return pathname === "/subscribe";
}

function isLoginPage(pathname: string) {
  return pathname === "/login";
}

function UserMenu({
  hideAccountInfo,
  hideOrganizations,
  variant = "default",
}: {
  hideAccountInfo: boolean;
  hideOrganizations?: boolean;
  variant?: "sidebar" | "default";
}) {
  const { signOut, user } = useOptionalAuthContext();
  const { identity } = useIdentity();
  const { data: organizations } = useOrganizations();
  const { switchOrganization, isLoadingSwitchOrganization } =
    useOrganizationMutation({
      onSettledSwitchOrg: () => {
        navigate("/");
      },
    });
  const navigate = useNavigate();
  const { setColorScheme } = useMantineColorScheme();

  const computedColorScheme = useComputedColorScheme("light", {
    getInitialValueInEffect: true,
  });
  const isClient = useIsRole(IdentityRole.Client);

  function onSwitchOrganization(orgId: string) {
    switchOrganization({ id: orgId });
    const organization = organizations.find((o) => o.id === orgId);
    notifications.show({
      message: `Connected to organization ${organization?.name}`,
      color: "green",
      icon: <IconSwitchHorizontal size={18} />,
    });
  }

  const loggedInOrganization = organizations.find(
    (o) => identity && o.id === identity?.organizationId,
  );
  const otherOrganizations = organizations.filter(
    (o) => identity && o.id !== identity?.organizationId,
  );

  return (
    <>
      {!hideAccountInfo && (
        <Menu shadow="sm" position="bottom-end">
          <Menu.Target>
            <Button
              color={variant === "sidebar" ? "dark" : undefined}
              size={variant === "sidebar" ? "sm" : undefined}
              variant="subtle"
              leftSection={
                <ThemeIcon
                  variant="transparent"
                  color={variant === "sidebar" ? "text" : undefined}
                  size={"sm"}
                >
                  <IconUser />
                </ThemeIcon>
              }
              classNames={{
                root: classes.accountButton,
                section: classes.accountButtonSection,
                label: classes.accountButtonLabel,
              }}
            >
              Account
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {!hideOrganizations && (
              <>
                <Menu.Label tt="uppercase" fw="bold">
                  {organizations.length === 1
                    ? "Connected to"
                    : "Use NOAN as..."}
                </Menu.Label>
                <ScrollAreaAutosize mah={500}>
                  <Spoiler
                    hideLabel="Show less"
                    showLabel="Show more"
                    styles={{
                      control: {
                        marginLeft: 12,
                        fontSize: 12,
                      },
                    }}
                    maxHeight={200}
                  >
                    {!!loggedInOrganization && (
                      <Menu.Item
                        disabled
                        rightSection={
                          <Badge size="xs" variant="light">
                            Logged in
                          </Badge>
                        }
                      >
                        <Tooltip label={loggedInOrganization.name}>
                          <Text truncate="end" w="150" size="sm">
                            {loggedInOrganization.name}
                          </Text>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {otherOrganizations.map((o) => (
                      <Menu.Item
                        key={o.id}
                        disabled={isLoadingSwitchOrganization}
                        onClick={() => {
                          onSwitchOrganization(o.id);
                        }}
                      >
                        <Tooltip label={o.name}>
                          <Text truncate="end" w="175" size="sm">
                            {o.name}
                          </Text>
                        </Tooltip>
                      </Menu.Item>
                    ))}
                  </Spoiler>
                </ScrollAreaAutosize>

                <Divider my={4} />
              </>
            )}

            {!isClient && (
              <Menu.Item
                component={Link}
                leftSection={
                  <ThemeIcon variant="transparent" color="dark" size="xs">
                    <IconSettings />
                  </ThemeIcon>
                }
                to="/account"
              >
                Settings
              </Menu.Item>
            )}

            <Menu.Item
              onClick={() =>
                setColorScheme(
                  computedColorScheme === "light" ? "dark" : "light",
                )
              }
              leftSection={
                <ThemeIcon variant="transparent" color="dark" size="xs">
                  {computedColorScheme === "light" ? <IconMoon /> : <IconSun />}
                </ThemeIcon>
              }
            >
              {computedColorScheme === "light" ? "Dark" : "Light"} mode
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                window.tidioChatApi.display(true);
                window.tidioChatApi.open();
              }}
              leftSection={
                <ThemeIcon variant="transparent" color="dark" size="xs">
                  <IconHelp />
                </ThemeIcon>
              }
            >
              Livechat support
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              color="red.4"
              leftSection={
                <ThemeIcon variant="transparent" size="xs" color="red.4">
                  <IconLogout />
                </ThemeIcon>
              }
              onClick={signOut}
            >
              Sign Out
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      )}
      {hideAccountInfo && user != null && (
        <Button
          variant="subtle"
          leftSection={<IconLogout size={18} />}
          onClick={signOut}
        >
          Sign Out
        </Button>
      )}
    </>
  );
}

function DefaultNavigation() {
  const { pathname } = useLocation();
  const hasInsightsAccess = !!useIsFeatureEnabled("insights");

  const { user } = useOptionalAuthContext();
  const isClient = useIsRole(IdentityRole.Client);

  const navigationLinks: NavigationLink[] = [
    {
      to: "/",
      label: "Dashboard",
      requiresSession: true,
      checkAccess: () => !isClient,
    },
    {
      to: "/create",
      label: "Create",
      requiresSession: true,
      strictPath: false,
      checkAccess: () => !isClient,
    },
    {
      to: "/build",
      label: "Build",
      requiresSession: true,
      checkAccess: () => !isClient,
    },
    {
      to: "/ideas",
      label: "Ideas",
      requiresSession: true,
      checkAccess: () => !isClient,
    },
    {
      to: "/insights",
      label: "Insights",
      requiresSession: true,
      checkAccess: () => !isClient && hasInsightsAccess,
    },
  ];

  const hideAccountInfoForRoute =
    user == null || isSignupPage(pathname) || isSubscribePage(pathname);

  const renderNavigationLinks = ({ menuItem }: { menuItem?: boolean }) => {
    return navigationLinks
      .map(
        ({
          to,
          label,
          requiresSession,
          hideIfLoggedIn,
          hidden,
          checkAccess,
          strictPath,
        }) => {
          if (hidden) return null;

          const isActive = strictPath
            ? location.pathname === to
            : location.pathname.startsWith(to);

          if (requiresSession === true && hideAccountInfoForRoute) {
            return null;
          }

          if (hideIfLoggedIn && user != null) {
            return null;
          }

          if (checkAccess && !checkAccess()) {
            return null;
          }

          const itemComponent = (
            <Anchor
              key={to}
              to={to}
              component={Link}
              fw={isActive ? 600 : undefined}
              c={isActive ? "currentColor" : undefined}
              underline={menuItem ? "never" : "hover"}
            >
              {label}
            </Anchor>
          );

          return menuItem ? (
            <Menu.Item key={to} px="xl">
              {itemComponent}
            </Menu.Item>
          ) : (
            itemComponent
          );
        },
      )
      .filter((link) => link != null);
  };

  const inlineNavigationLinks = renderNavigationLinks({ menuItem: false });
  const mobileNavigationLinks = renderNavigationLinks({ menuItem: true });

  return (
    <Box role="navigation" h={navigationHeaderHeights.default}>
      <Flex
        style={{
          borderBottom: "1px solid var(--mantine-color-default-border)",
          backgroundColor: "var(--mantine-color-body)",
        }}
        p={{ base: "sm" }}
        h={{ base: "auto", sm: 80 }}
        direction="row"
      >
        <Group flex={1}>
          <Group flex={1}>
            {!isClient && (
              <Link to="/">
                <Center px={{ base: 0, md: "md" }} visibleFrom="sm">
                  <NoanLogo />
                </Center>
                <Center px={{ base: 0, md: "md" }} hiddenFrom="sm">
                  <NoanLogoHexagon />
                </Center>
              </Link>
            )}
            {mobileNavigationLinks.length > 0 && (
              <Group hiddenFrom="sm">
                <Menu>
                  <Menu.Target>
                    <ActionIcon variant="subtle">
                      <IconMenu2 size={24} />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>{mobileNavigationLinks}</Menu.Dropdown>
                </Menu>
              </Group>
            )}

            <Group gap="lg" ml={"lg"} preventGrowOverflow visibleFrom="sm">
              {inlineNavigationLinks}
            </Group>
          </Group>

          <Group>
            {user == null && !isLoginPage(pathname) && (
              <Button
                to="/login"
                component={Link}
                variant="light"
                size="sm"
                leftSection={<IconLogin2 size={18} />}
              >
                Login
              </Button>
            )}

            <UserMenu hideAccountInfo={hideAccountInfoForRoute} />
          </Group>
        </Group>
      </Flex>
    </Box>
  );
}

function PreviewBanner() {
  return (
    <Container bg="orange.4" fluid>
      <Group py={6}>
        <Text size="xs" fw="bold" c="white">
          NOAN Preview
        </Text>
        <Text size="xs" c="white">
          You're using a limited preview of NOAN.{" "}
          <Anchor component={Link} to={"/subscribe"} c="yellow.1" fw="bold">
            Subscribe
          </Anchor>{" "}
          today to unlock all features.
        </Text>
      </Group>
    </Container>
  );
}

function OrganizationSwitcherItem({
  organizationId,
  active = false,
  name,
  onSelectOrganization,
  rightSection,
}: {
  organizationId?: string;
  active?: boolean;
  name?: string;
  onSelectOrganization?: () => void;
  rightSection?: JSX.Element;
}) {
  const orgLogo = useOrgLogo({
    organizationId,
  });

  const showLogo = orgLogo && !active;

  return (
    <Group
      flex={1}
      gap="xs"
      onClick={onSelectOrganization}
      w="100%"
      className={clsx(active && classes.sidebarOrgSwitcherItemActive)}
    >
      {/* Set the icon color based on org name initials, if looking at the list of orgs */}

      <Avatar
        variant={active ? "filled" : "light"}
        color={active ? "orange" : "dimmed"}
        name={name}
        src={showLogo ? orgLogo : undefined}
        radius={showLogo ? 0 : "xl"}
        classNames={{
          image: classes.sidebarOrgSwitcherLogo,
        }}
      >
        <IconUsersGroup />
      </Avatar>

      <Group className={classes.sidebarOrgSwitcherItemLabelAction} flex={1}>
        <Box size="md" flex={rightSection ? 1 : undefined} pl="xs">
          {name || <Skeleton w={120} h={25} animate />}
        </Box>
        {rightSection}
      </Group>
    </Group>
  );
}

export function AuthedLayout({ variant }: { variant?: LayoutVariant }) {
  const posthog = usePostHog();
  const {
    user,
    signOut,
    isLoadingUser,
    authError,
    jwtBearerToken,
    mustSetPassword,
    hasThirdPartyProvider,
    mustCompletePostSignup,
  } = useOptionalAuthContext();
  const { pathname } = useLocation();
  const {
    identity,
    isActive,
    isCustomer,
    isPaying,
    isLoading: isLoadingIdentity,
    hasInsightsAccess,
    isError: identityError,
    mustCompleteOnboarding,
  } = useIdentity();
  const showPreviewBanner =
    !isLoadingIdentity && !isActive && !isSubscribePage(pathname);

  /**
   * Once we have user information, update third-party tools:
   */
  useEffect(() => {
    if (isDeployed && user != null) {
      try {
        if (window.tidioChatApi) {
          window.tidioChatApi.setVisitorData({
            distinct_id: user.id,
            email: user.email,
          });
        }

        posthog.identify(`${user.email}#${user.id.slice(0, 6)}`, {
          email: user.email,
          id: user.id,
        });
      } catch (err) {
        console.warn("Error while identifying user");
        console.error(err);
      }
    }
  }, [user, posthog]);

  if (identityError) {
    signOut();
    return null;
  }

  if (mustCompletePostSignup && pathname !== "/join") {
    // User must complete post-signup tasks
    return <Navigate to="/join" />;
  } else if (authError != null) {
    const errorType =
      isNoanError(authError) && authError.errorType === NoanError.NotLoggedIn
        ? "no-auth"
        : "auth-error";

    // Unable to fetch auth for some reason - might be missing user.
    return <Navigate to={`/login?reason=${errorType}`} />;
  } else if (isLoadingUser || isLoadingIdentity) {
    // Is loading user or identity data, and there's no cached identity data available
    return <FullPageLoader />;
  } else if (mustCompleteOnboarding && pathname !== "/welcome") {
    return <Navigate to={"/welcome"} />;
  } else if (
    (user == null && !isLoadingUser) ||
    (identity == null && !isLoadingIdentity)
  ) {
    return <Navigate to="/login?reason=no-auth" />;
  }

  return (
    <AuthContext.Provider
      value={{
        jwtBearerToken,
        user: user!,
        identity: identity!,
        isAccountActive: isActive,
        isCustomer,
        isPayingCustomer: isPaying,
        isLoadingIdentity,
        isLoadingUser,
        authError,
        mustSetPassword,
        hasThirdPartyProvider,
        mustCompletePostSignup,
        mustCompleteOnboarding,
        signOut,
        hasInsightsAccess,
      }}
    >
      <Layout
        variant={variant}
        topHeader={showPreviewBanner ? <PreviewBanner /> : undefined}
      />
    </AuthContext.Provider>
  );
}

interface LayoutProps {
  variant?: LayoutVariant;
  topHeader?: JSX.Element;
  withPadding?: boolean;
}

function SidebarLayout({ topHeader }: Pick<LayoutProps, "topHeader">) {
  const navigate = useNavigate();
  const location = useLocation();

  const [
    onboardingVideoOpen,
    { open: openOnboardingVideo, close: closeOnboardingVideo },
  ] = useDisclosure();

  /**
   * Collapse/hide navigation + mobile behavior
   */
  const [navOpened, { toggle: toggleNav, close: closeNav }] = useDisclosure();
  const [navCollapsed, setNavCollapsed] = useLocalStorage({
    key: "noan-navbar-collapsed",
    defaultValue: false,
  });

  /**
   * Account information + org switcher
   */
  const isClient = useIsRole(IdentityRole.Client);
  const { identity } = useAuthContext();
  const { data: organizations } = useOrganizations();
  const { switchOrganization, isLoadingSwitchOrganization } =
    useOrganizationMutation({
      onSettledSwitchOrg: () => {
        navigate("/");
      },
    });

  const activeOrganization = organizations.find(
    (o) => o.id === identity.organizationId,
  );
  const availableOrganizations = organizations.filter(
    (o) => o.id !== identity.organizationId,
  );

  const hasMultipleOrganizations =
    !isClient && availableOrganizations.length > 0;

  const orgLogo = useOrgLogo({ organizationId: activeOrganization?.id });

  const theme = useMantineTheme();
  const isMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const isWorkspaceEnabled = useIsFeatureEnabled(FeatureFlag.UseWorkspace);

  const onboardingCalloutPosition = isMobileView ? "bottom" : "right";
  const onboardingCalloutIsHidden = isMobileView && !navOpened;

  const [viewedOnboardingVideo, setViewedOnboardingVideo] =
    useLocalStorage<boolean>({
      key: "noan-viewed-onboarding-video",
      getInitialValueInEffect: true,
      defaultValue: false,
    });

  const pulseOnboardingVideo = useMemo(
    () =>
      !viewedOnboardingVideo &&
      dayjs(identity.createdAt).isAfter(dayjs().subtract(3, "weeks")),
    [identity, viewedOnboardingVideo],
  );

  /**
   * Ensure the mobile nav gets toggled off when navigating elsewhere. This is important
   * if we're on mobile, otherwise the nav is over the content and you can't tell
   * we've navigated elsewhere
   */
  useEffect(() => {
    closeNav();
  }, [location, closeNav]);

  return (
    <ModalsProvider
      modalProps={{
        withinPortal: true,
      }}
    >
      <ReaderPanelContextProvider>
        <AppShell
          layout="default"
          header={{ height: { base: 60, sm: 0 } }}
          navbar={{
            width: { base: navCollapsed ? 100 : 256 },
            breakpoint: "sm",
            collapsed: { mobile: !navOpened },
          }}
          padding={{
            base: 0,
          }}
          classNames={{
            root: classes.appShell,
            main: classes.appMain,
          }}
        >
          <AppShell.Navbar
            className={classes.sidebarNavbar}
            zIndex={navOpened ? ZedIndex.Sidebar : undefined}
          >
            {/* Desktop Logo */}
            <AppShell.Section>
              <Group className={classes.sidebarExpandButtonContainer}>
                <ActionIcon
                  variant="default"
                  color="dark"
                  onClick={() => setNavCollapsed(!navCollapsed)}
                  size="lg"
                  className={classes.sidebarExpandButton}
                >
                  {navCollapsed ? (
                    <IconLayoutSidebarLeftExpand />
                  ) : (
                    <IconLayoutSidebarLeftCollapse />
                  )}
                </ActionIcon>
              </Group>

              <Stack
                align="center"
                gap="md"
                justify="space-between"
                py="lg"
                px="sm"
                className={classes.sidebarLayoutLogo}
                visibleFrom="sm"
              >
                {orgLogo ? (
                  <Image src={orgLogo} w={50} fit="contain" />
                ) : (
                  <NoanLogo />
                )}
              </Stack>
            </AppShell.Section>

            <AppShell.Section grow component={ScrollArea}>
              <Menu
                position="bottom-start"
                offset={3}
                width={380}
                disabled={
                  !hasMultipleOrganizations || isLoadingSwitchOrganization
                }
                withArrow
                arrowPosition="side"
                arrowOffset={30}
                arrowSize={10}
              >
                <Menu.Target>
                  <Group
                    w="100%"
                    px="sm"
                    py="xs"
                    align="center"
                    component={"button"}
                    justify="flex-start"
                    className={clsx([
                      classes.sidebarOrgSwitcherButton,
                      hasMultipleOrganizations &&
                        classes.sidebarOrgSwitcherButtonEnabled,
                    ])}
                  >
                    <OrganizationSwitcherItem
                      active
                      organizationId={activeOrganization?.id}
                      name={activeOrganization?.name}
                      rightSection={
                        hasMultipleOrganizations ? (
                          <ThemeIcon variant="transparent" color="dimmed">
                            {isLoadingSwitchOrganization ? (
                              <Loader size="sm" color="dimmed" />
                            ) : (
                              <IconChevronRight />
                            )}
                          </ThemeIcon>
                        ) : undefined
                      }
                    />
                  </Group>
                </Menu.Target>

                <Menu.Dropdown>
                  <ScrollAreaAutosize
                    mah={200}
                    type="hover"
                    scrollbars="y"
                    scrollbarSize={4}
                    offsetScrollbars
                  >
                    {availableOrganizations.map((org) => (
                      <Menu.Item key={org.id}>
                        <OrganizationSwitcherItem
                          name={org.name}
                          organizationId={org.id}
                          onSelectOrganization={() => {
                            switchOrganization({
                              id: org.id,
                            });
                          }}
                          rightSection={
                            <Text size="xs" c="dimmed">
                              Switch organization
                            </Text>
                          }
                        />
                      </Menu.Item>
                    ))}
                  </ScrollAreaAutosize>
                </Menu.Dropdown>
              </Menu>

              {!isClient && (
                <Stack mt="lg" flex={1} gap={5} w="100%">
                  <OnboardingCallout
                    calloutId={OnboardingCalloutKey.Dashboard}
                    position={onboardingCalloutPosition}
                    forceHidden={onboardingCalloutIsHidden}
                  >
                    <NavLink to="/" icon={<IconHome />} label="Dashboard" />
                  </OnboardingCallout>
                  <OnboardingCallout
                    calloutId={OnboardingCalloutKey.Build}
                    position={onboardingCalloutPosition}
                    forceHidden={onboardingCalloutIsHidden}
                  >
                    <NavLink to="/build" icon={<IconStack2 />} label="Build" />
                  </OnboardingCallout>
                  <OnboardingCallout
                    calloutId={OnboardingCalloutKey.Create}
                    position={onboardingCalloutPosition}
                    forceHidden={onboardingCalloutIsHidden}
                  >
                    <NavLink
                      to="/create"
                      icon={<IconPencil />}
                      label="Create"
                    />
                  </OnboardingCallout>
                  <OnboardingCallout
                    calloutId={OnboardingCalloutKey.Ideas}
                    position={onboardingCalloutPosition}
                    forceHidden={onboardingCalloutIsHidden}
                  >
                    <NavLink to="/ideas" icon={<IconBulb />} label="Ideas" />
                  </OnboardingCallout>

                  {isWorkspaceEnabled && (
                    <NavLink
                      to="/workspace"
                      icon={<IconDesk />}
                      label="Workspace"
                    />
                  )}
                </Stack>
              )}
            </AppShell.Section>

            <Group
              align="center"
              justify="space-between"
              className={classes.accountButtonContainer}
            >
              <Tooltip label="Watch a short onboarding video">
                <ActionIcon
                  onClick={() => {
                    setViewedOnboardingVideo(true);
                    openOnboardingVideo();
                  }}
                  variant={pulseOnboardingVideo ? "outline" : "subtle"}
                  size="lg"
                  color={pulseOnboardingVideo ? "orange" : "dark"}
                  className={
                    (pulseOnboardingVideo && "animate-firstuse") || undefined
                  }
                >
                  <IconSchool />
                </ActionIcon>
              </Tooltip>

              <Group flex={1} justify="flex-end">
                <UserMenu
                  hideAccountInfo={false}
                  hideOrganizations
                  variant="sidebar"
                />
              </Group>
            </Group>
            <FirstTimeVideoModal
              opened={onboardingVideoOpen}
              onClose={() => closeOnboardingVideo()}
            />
          </AppShell.Navbar>
          <AppShell.Header hiddenFrom="sm">
            {/* Mobile Header + Logo */}
            <Group w="100%" h="100%" px="xl" justify="space-between">
              <NoanLogo width={90} />
              <Burger opened={navOpened} onClick={toggleNav} />
            </Group>
          </AppShell.Header>
          <AppShell.Main>
            <Box visibleFrom="sm">{topHeader}</Box>
            <Box className={classes.outletContainer}>
              <Outlet />
            </Box>
          </AppShell.Main>
        </AppShell>
      </ReaderPanelContextProvider>
    </ModalsProvider>
  );
}

function DefaultLayout({ variant, topHeader, withPadding }: LayoutProps) {
  const shellPadding = { base: "sm", md: "xl" };
  const navigationHeaderHeight = variant ? navigationHeaderHeights[variant] : 0;
  const mainPadding = {
    base: navigationHeaderHeight + (topHeader ? topHeaderHeights.mobile : 0),
    xs: navigationHeaderHeight + (topHeader ? topHeaderHeights.desktop : 0),
  };

  return (
    <ReaderPanelContextProvider>
      <AppShell
        p={withPadding ? shellPadding : undefined}
        bg="var(--mantine-color-body)"
      >
        <AppShell.Header zIndex={ZedIndex.Header} withBorder={false}>
          {topHeader}
          {variant === "default" && <DefaultNavigation />}
        </AppShell.Header>

        <AppShell.Main pt={mainPadding}>
          <Outlet />
          <ScrollRestoration getKey={(location) => location.pathname} />
        </AppShell.Main>
      </AppShell>
    </ReaderPanelContextProvider>
  );
}

export function MinimalLayout() {
  return (
    <div className={classes.minimalRoot}>
      <Outlet />
    </div>
  );
}

export function Layout({ variant, topHeader, withPadding }: LayoutProps) {
  /**
   * Redirect beta.getnoan.com to app.getnoan.com in all cases, preserving
   * path information.
   */
  useEffect(() => {
    const { host, pathname, search } = document.location;

    if (host === "beta.getnoan.com") {
      const newLocation = new URL(`https://app.getnoan.com${pathname}`);
      newLocation.search = search;
      // document.location.assign(newLocation);
    }
  }, []);

  if (variant === "sidebar") {
    return <SidebarLayout topHeader={topHeader} />;
  } else if (variant === "minimal") {
    return <MinimalLayout />;
  } else {
    return (
      <DefaultLayout
        withPadding={withPadding}
        variant={variant}
        topHeader={topHeader}
      />
    );
  }
}
