import { z } from "zod";

export enum FeedScope {
  News = "news",
}

export const blockPathsPerFeed = {
  [FeedScope.News]: ["business-sector", "product-type", "audience-location"],
};

export const FeedEntrySourceSchema = z.object({
  sourceUrl: z.string().optional(),
  title: z.string().optional(),
  author: z.string().optional(),
  // E.g "article", "post", etc.
  mediaType: z.string().optional(),
  // E.g "text", "video", "image", "audio", etc.
  mediaFormat: z.string().optional(),
});

export const FeedEntryGeneratedSchema = z.object({
  feedEntries: z.array(
    FeedEntrySourceSchema.extend({
      scope: z.nativeEnum(FeedScope),
      content: z.string(),
      type: z.string(),
      publishedAt: z.string(),
    }),
  ),
});

export const FeedEntryFilterSchema = z.object({
  scope: z.nativeEnum(FeedScope),
});

export type FeedEntrySource = z.infer<typeof FeedEntrySourceSchema>;
export type FeedEntryGenerated = z.infer<typeof FeedEntryGeneratedSchema>;
export type FeedEntryFilter = z.infer<typeof FeedEntryFilterSchema>;
