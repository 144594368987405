import { z } from "zod";

export enum PerkId {
  Squarespace = "squarespace-domain",
  Squarespace20 = "squarespace-20",
}

export const CouponSchema = z.object({
  id: z.string().uuid(),
  productId: z.string(),
  code: z.string(),
  usedAt: z.date().nullable(),
  usedBy: z.string().uuid().nullable(),
  claimedBy: z.string().uuid().nullable(),
  createdAt: z.date(),
});

export type Coupon = z.infer<typeof CouponSchema>;
